import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  cards = [
    {
      icon: 'oxygen-black.png',
      image: 'oxygen-black.png',
      icon2: 'oxygen.png',
      text:
        'Administración de medicamentos por via inhalada, asi como oxígeno y humedad.'
    },
    {
      icon: 'athlete-black2.png',
      image: 'athlete-black2.png',
      icon2: 'athlete2.png',
      text:
        'Evaluación, fisioterapia pulmonar del deportista y rehabilitación respiratoria. Asistencia en trastornos respiratorios del dormir'
    },
    {
      icon: 'teacher-black.png',
      image: 'teacher-black.png',
      icon2: 'teacher.png',
      text:
        'Capacitación para pacientes y familiares en el uso de equipo de diagnóstico, terapéutico, de soporte y de monitoreo.'
    }
  ];

  constructor() {}

  ngOnInit() {}
}
