import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import GoogleMaps from 'google-maps';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements AfterViewInit {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('gmap2') gmapElement2: any;
  @ViewChild('gmap3') gmapElement3: any;
  map: any;
  marker: any;

  constructor() {}
  // 88c3c4

  ngAfterViewInit() {
    GoogleMaps.KEY = 'AIzaSyAXfUudLFpvBvVQxCtsI-rG1zSG8ZkQL0Y';
    GoogleMaps.load(google => {
      const mapProp = {
        center: new google.maps.LatLng(19.3998155,-99.1748179),
        zoom: 16,
        disableDefaultUI: true,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
        ]
      };
      const mark = { lat: 19.3998155, lng: -99.1748179 };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.marker = new google.maps.Marker({ position: mark, map: this.map });
      this.marker.setIcon('https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
    });

    GoogleMaps.load(google => {
      const mapProp = {
        center: new google.maps.LatLng(19.312454, -99.220866),
        zoom: 16,
        disableDefaultUI: true,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
        ]
      };
      const mark = { lat: 19.312515, lng: -99.220866 };
      this.map = new google.maps.Map(this.gmapElement2.nativeElement, mapProp);
      this.marker = new google.maps.Marker({ position: mark, map: this.map });
      this.marker.setIcon('https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
    });

    GoogleMaps.load(google => {
      const mapProp = {
        center: new google.maps.LatLng(19.299431793354096, -99.13500455454191),
        zoom: 16,
        disableDefaultUI: true,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
        ]
      };
      const mark = { lat: 19.299431793354096, lng: -99.13500455454191 };
      this.map = new google.maps.Map(this.gmapElement3.nativeElement, mapProp);
      this.marker = new google.maps.Marker({ position: mark, map: this.map });
      this.marker.setIcon('https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
    });
  }

}
