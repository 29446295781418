import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UserForm} from '../models/userForm';
import {Observable} from 'rxjs';
import {Appointment} from '../models/appointment';

@Injectable({
  providedIn: 'root'
})
export class ConsultService {
  private serverUrl = environment.endpoint;

  constructor(private http: HttpClient) { }

  makeAppointment(form: UserForm): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this.http.post(this.serverUrl, JSON.stringify(form), {headers});
  }
}
