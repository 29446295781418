import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConsultService} from '../consult/services/consult.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerForm: FormGroup;

  constructor( private formBuilder: FormBuilder,
               private consultService: ConsultService,
               private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.footerForm = this.formBuilder.group({
      first_name: [null],
      last_name: [null],
      phone: [null],
      doctor: [null],
      date: [null],
      time: [null],
      hospital: [null],
      message: [null],
      email: [null, [Validators.required, Validators.email]]
    });

    this.footerForm.controls.email.valueChanges
      .pipe(debounceTime(3000))
      .subscribe(value => {
        if (value && this.footerForm.valid) {
          this.submit();
        }
      });
  }

  submit() {
    const data = this.footerForm.getRawValue();
    this.consultService.makeAppointment(data).subscribe((res) => {
      this.openSnackBar(res.message, '');
    }, error => {
      this.openSnackBar(error, '');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
