import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ConsultService} from './services/consult.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: ['./consult.component.scss']
})
export class ConsultComponent implements OnInit {
  public today = new Date();
  hospitals = [
    {value: 'mexico', viewValue: 'Angeles México'},
    {value: 'pedregal', viewValue: 'Angeles del Pedregal'}
  ];
  consultForm: FormGroup;
  loading = false;

  constructor( private formBuilder: FormBuilder,
               private dataPipe: DatePipe,
               private consultService: ConsultService,
               private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.consultForm = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null, Validators.required],
      doctor: [null],
      date: [null, Validators.required],
      time: [null, [Validators.required, Validators.pattern('([0][9]|[1][0]|[1][1]|[1][2]|[1][3]|[1][4]|[1][5]|[1][6])[:]{1}[0-9]{2}')]],
      hospital: [null, Validators.required],
      message: [null],
      email: [null, [Validators.required,Validators.email]]
    });
  }

  submit() {
    const data = this.consultForm.getRawValue();
    data.date = this.dataPipe.transform(data.date, 'yyyy-MM-dd');
    data.phone = data.phone.toString();
    // data.date = this.dataPipe.transform(data.date, 'fullDate');
    this.loading = true;
    this.consultService.makeAppointment(data).subscribe((res) => {
      this.loading = false;
      this.openSnackBar(res.message, '');
    }, error => {
      this.loading = false;
      if(error.status == 200){
        this.openSnackBar(error.error.text, '');
      }else{
        this.openSnackBar(error.message, '');
      }
    });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
