import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isExpanded: Boolean;

  constructor() {}

  toggleExpand() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
  }

  ngOnInit() {}

  onAnchorClick() {}
}
